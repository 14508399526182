html {
  box-sizing: border-box;

  /* https://refactoringui.com/previews/building-your-color-palette/ */

  --primaryBase: #3183c8;
  --primaryDark: #2368a2;
  --primaryDarker: #1a4971;
  --primaryDarkest: #203d54;
  --primaryLight: #63a2d8;
  --primaryLighter: #aad4f5;
  --primaryLightest: #eff8ff;

  --neutralBase: #b8c4ce;
  --neutralDark: #8895a7;
  --neutralDarkest: #5f6b7a;
  --neutralDarker: #212934;
  --neutralLight: #cfd6de;
  --neutralLighter: #e1e7ec;
  --neutralLightest: #f8f9fa;

  --accentCyanBase: #3caea3;
  --accentCyanDark: #2a9187;
  --accentCyanDarker: #1b655e;
  --accentCyanDarkest: #124544;
  --accentCyanLight: #6ed7d3;
  --accentCyanLighter: #a8eeeb;
  --accentCyanLightest: #e7fffe;

  --accentGreenBase: #38c172;
  --accentGreenDark: #259d58;
  --accentGreenDarker: #197741;
  --accentGreenDarkest: #145239;
  --accentGreenLight: #74d99f;
  --accentGreenLighter: #a8eec1;
  --accentGreenLightest: #e3fcec;

  --accentYellowBase: #f4ca64;
  --accentYellowDark: #caa53d;
  --accentYellowDarker: #8c6d1f;
  --accentYellowDarkest: #5c4813;
  --accentYellowLight: #fae29f;
  --accentYellowLighter: #fdf3d7;
  --accentYellowLightest: #fffcf4;

  --accentRedBase: #dc3030;
  --accentRedDark: #b82020;
  --accentRedDarker: #891b1b;
  --accentRedDarkest: #611818;
  --accentRedLight: #e46464;
  --accentRedLighter: #f5aaaa;
  --accentRedLightest: #fce8e8;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
